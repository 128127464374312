import React from "react"
import { Box, Select, useDisclosure, Flex } from "@chakra-ui/core"
import { useDispatch, useSelector } from "react-redux"
import capitalize from "lodash/capitalize"
import R from "ramda"
import loadable from "@loadable/component"

import MaxWidthGrid from "../components/Layout/MaxWidthGrid"
import {
  BikePageHeader,
  LabeledSelector,
} from "../components/BikeProductPage/ProductActions"
import LayoutBuilder from "../components/LayoutBuilder"
import BikeImages from "../components/BikeProductPage/BikeImages"
import { Button } from "../components/Buttons"
import ColorPicker from "../components/ColorPicker"
import Body from "../components/typography/Body"
import CloseSideBar from "../components/CloseSideBar"
import { ConnectedAddedToCartModal as AddedToCartModal } from "../components/Cart/AddedToCartModal"
import DetailsItem from "../components/DetailsItem"
import Notice from "../components/Notice"

import { BaseDispatch, BaseRootState } from "../redux/store"
import { CartItem } from "../redux/models/checkout"
import {
  createContentfulImageMap,
  decodeShopifyId,
  placeholderImage,
} from "../utils"
import { bp, LaptopDownOnly, LaptopUpOnly } from "../utils/MediaQueries"
import { AccessoryPageQuery } from "../../graphql-types"
import { getFixedPrice } from "../components/Cart/utils"
import { getPreorderLabel, useFetchPreorderInfo } from "../utils/preorder"
import BackInStockButton from "../components/BackInStockButton/connected"
import SEO from "../components/boilerplate/seo"
import GatsbyImage, { FluidObject } from "gatsby-image"
import { StringParam, useQueryParam } from "use-query-params"
import useGoogleAnalytics from "../components/GoogleAnalytics/hooks/useGoogleAnalytics"
import useShopifyVariantPricing from "../hooks/useShopifyVariantPricing"
import ReviewsButton from "../components/ReviewsButton"
import useJudgeMeReviews from "../hooks/useJudgeMeReviews"
import Banner from "../components/Banner"
import { HEADER_HEIGHT } from "../constants/measurements"
import SlashedText from "../components/SlashedText"
import { trackAddToCart } from "../components/Scripts/EventTracker"
import UpsellAccessories from "../components/BikeProductPage/UpsellAccessories"

export type Accessory = {
  contentful_id: string
  shopifyId: string
  internalTitle: string
  productListImage: any
  title: string
  topLevelImages: any
  type: string
  variants: any
  pricing: any
}

const LoadableAffirmWidget = loadable(
  () => import("../components/AffirmWidget")
)

export interface AccessoryPageProps {
  data: AccessoryPageQuery
  location: Location
}

const AccessoryPage: React.FC<AccessoryPageProps> = ({ data, location }) => {
  const [addedToCart, setAddedToCart] = React.useState(false)
  const addedToCartModal = useDisclosure()

  // SelectedAccessories
  const [selectedAccessories, setSelectedAccessories] = React.useState<
    Accessory[]
  >([])

  // Redux
  const dispatch = useDispatch<BaseDispatch>()
  const { isCartLoading } = useSelector((state: BaseRootState) => ({
    isCartLoading: state.checkout.isLoading,
  }))

  let imageMap = {}
  if (data.images.edges.length > 0) {
    imageMap = createContentfulImageMap(data.images)
  }

  const variants = data.contentfulAccessoryModel?.variants ?? []

  // Pricing
  const { pricingMap } = useShopifyVariantPricing(
    R.uniq(R.flatten(variants.map((v) => v?.shopifyId)))
  )

  const COLORS = variants.reduce((acc, cur) => {
    if (
      cur?.color &&
      acc.find(
        (val) =>
          val.hexCode === cur.color?.hexCode && val.name === cur.color.name
      ) === undefined
    ) {
      if (cur.color?.hexCode && cur.color?.name)
        return [...acc, { hexCode: cur.color.hexCode, name: cur.color?.name }]
    }
    return acc
  }, [] as Array<{ hexCode: string; name: string }>)
  const [
    currentVariantId = decodeShopifyId(variants?.[0]?.shopifyId ?? null),
    setCurrentVariantId,
  ] = useQueryParam("variant", StringParam)
  const curVariant = variants.find(
    (variant) => decodeShopifyId(variant?.shopifyId) === currentVariantId
  )
  const pricing = curVariant?.shopifyId
    ? pricingMap[curVariant.shopifyId]
    : null

  // Preorder info
  const {
    data: preorderInfo,
    error: preorderInfoError,
    loading: preorderInfoLoading,
  } = useFetchPreorderInfo()
  const availableForSale = pricing?.availableForSale
  const productShopifyId = pricing?.product.id
  const preorderLabel = getPreorderLabel(
    preorderInfo?.data,
    currentVariantId,
    productShopifyId,
    false
  )
  const isAvailable =
    pricing && availableForSale && !pricing.currentlyNotInStock
  const isPreorder = pricing && availableForSale && pricing.currentlyNotInStock

  const title = data.contentfulAccessoryModel!.title ?? ""
  const price = getFixedPrice(pricing?.priceV2?.amount)
  const compareAtPrice = pricing?.compareAtPriceV2?.amount
    ? getFixedPrice(pricing.compareAtPriceV2.amount)
    : undefined
  const limitedQuantity =
    (pricing?.quantityAvailable || Infinity) < 10
      ? pricing?.quantityAvailable
      : undefined

  const [imagesLayoutState, setImagesLayoutState] = React.useState<Array<any>>(
    []
  )

  React.useEffect(() => {
    let images: Array<any> = []
    let imageLayout: any = {}
    if (data.contentfulAccessoryModel?.topLevelImages?.internal?.content) {
      imageLayout = JSON.parse(
        data.contentfulAccessoryModel?.topLevelImages?.internal?.content
      )?.layout
      if (imageLayout) {
        images = [...imageLayout]
      }
    }
    if (curVariant?.imageLayout) {
      let variantImagesContent = curVariant?.imageLayout?.internal?.content
      if (variantImagesContent) {
        const variantImages = JSON.parse(variantImagesContent)?.layout
        if (variantImages) images = [...variantImages, ...images]
      }
    }
    setImagesLayoutState(images)
  }, [currentVariantId])

  // Show AddedToCartModal after cart is done loading
  React.useEffect(() => {
    if (addedToCart && isCartLoading === false) {
      setAddedToCart(false)
      addedToCartModal.onOpen()
    }
  }, [isCartLoading])

  const accessoryGA = useGoogleAnalytics({
    shouldFireOnFirstRender: false,
    category: "Accessory",
    action: "Added Product",
  })

  const reviews = useJudgeMeReviews(pricing?.product.handle)

  // On Clicks
  const onAddToCart = () => {
    // -------
    let itemList = [
      {
        cartItem: {
          type: "accessory",
          productSlug: data.contentfulAccessoryModel?.internalTitle,
          contentfulProductId: data.contentfulAccessoryModel?.contentful_id,
          contentfulVariantId: curVariant?.contentful_id,
          variantId: curVariant?.shopifyId,
          isPreorder,
          preorderInfo: preorderLabel,
        } as CartItem,
        childCartItems: [],
      },
    ]

    if (Array.isArray(selectedAccessories) && selectedAccessories.length > 0) {
      selectedAccessories.map((upsellItem) => {
        itemList.push({
          cartItem: {
            type: "accessory",
            productSlug: upsellItem?.internalTitle,
            contentfulProductId: upsellItem?.contentful_id,
            contentfulVariantId: upsellItem?.contentful_id,
            variantId: upsellItem?.shopifyId,
            isPreorder,
            preorderInfo: preorderLabel,
          } as CartItem,
          childCartItems: [],
        })

        const value = upsellItem?.pricing?.priceV2?.amount || 0
        trackAddToCart({
          productId: decodeShopifyId(upsellItem?.shopifyId) || "",
          productName: upsellItem?.title || "",
          productType: "Accessory",
          value,
        })
      })
    }

    dispatch.checkout.addToCartBulk(itemList)
    setAddedToCart(true)
    // -------

    const value = pricing?.priceV2?.amount || 0
    accessoryGA.fireEvent()
    trackAddToCart({
      productId: decodeShopifyId(productShopifyId) || "",
      productName: title || "",
      productType: data.contentfulAccessoryModel?.type || "Accessory",
      value,
    })
  }

  const saleStatus = isAvailable
    ? "available"
    : isPreorder
    ? "preorder"
    : "out-of-stock"

  // Google Analytics Accesory Event
  useGoogleAnalytics({ category: "Accessories", action: "Viewed Product" })

  // Google Analytics Accessory Type Event
  const accessoryCategory =
    data.contentfulAccessoryModel?.type?.split(" ").map(capitalize).join(" ") ??
    ""
  useGoogleAnalytics({
    category: accessoryCategory,
    action: "Viewed Product",
    shouldFireOnFirstRender: accessoryCategory !== "",
  })

  const onAccessoryChange = (accessory: Accessory) => {
    const isAccessoryPresent = selectedAccessories?.find(
      (acc) => acc?.contentful_id === accessory?.contentful_id
    )
    if (isAccessoryPresent) {
      setSelectedAccessories(
        selectedAccessories?.filter(
          (acc) => acc?.contentful_id !== accessory?.contentful_id
        )
      )
    } else {
      setSelectedAccessories([...selectedAccessories, accessory])
    }
  }

  return (
    <>
      <SEO
        title={
          data.contentfulAccessoryModel?.seoTitle ||
          data.contentfulAccessoryModel?.title!
        }
        description={
          data.contentfulAccessoryModel?.seoDescription?.seoDescription || ""
        }
        image={curVariant?.productListingImage?.fluid?.src || undefined}
        location={location}
      />
      {data.contentfulAccessoryModel?.banner?.fluid && (
        <Banner
          image={data.contentfulAccessoryModel?.banner?.fluid as FluidObject}
          mobileImage={
            (data.contentfulAccessoryModel?.mobileBanner
              ?.fluid as FluidObject) || undefined
          }
          url={data.contentfulAccessoryModel.bannerSlug}
          paddingTop={bp(`${HEADER_HEIGHT}px`, 0)}
        />
      )}
      <CloseSideBar>
        <Box pt={["4.5rem", null, null, null, "4.5rem"]}>
          <MaxWidthGrid>
            <LaptopDownOnly>
              <Box gridColumn="1 / 3" mb="0.75rem">
                <BikePageHeader title={title} />
                <Flex>
                  <Body fontWeight="bold" color="night" mb="0.1rem">
                    {price}
                  </Body>
                  {compareAtPrice && (
                    <SlashedText ml="1rem">{compareAtPrice}</SlashedText>
                  )}
                </Flex>
                <LoadableAffirmWidget
                  pageType="product"
                  amount={Number(price?.replace("$", "")) || 0}
                />
                <ReviewsButton
                  bikeName={title}
                  w="fit-content"
                  mt="1.2rem"
                  mb="1.2rem"
                  isLoading={reviews.isLoading}
                  rating={reviews.avgRating}
                  reviewCount={reviews.numReviews}
                  reviews={R.uniqBy(R.prop("id"), reviews.seoReviews)}
                  productId={reviews.judgemeProduct?.product.id}
                  loadMoreReviews={
                    reviews.hasMoreToLoad ? reviews.loadMore : undefined
                  }
                  isLoadingMore={reviews.isLoadingMore}
                />
              </Box>
            </LaptopDownOnly>
            {/* Images */}
            {imagesLayoutState?.length > 0 && (
              <BikeImages
                gridColumn={["1 / 3", null, null, null, "2 / 8"]}
                data={imagesLayoutState}
                imageMap={imageMap}
                w={["100vw", null, null, null, "100%"]}
                m="0 -1.25rem"
                mb={[null, null, null, null, "8.125rem"]}
              />
            )}
            {imagesLayoutState?.length === 0 &&
              curVariant?.productListingImage && (
                <Box gridColumn={["1 / 3", null, null, null, "2 / 8"]}>
                  <GatsbyImage
                    fluid={curVariant.productListingImage.fluid as FluidObject}
                  />
                </Box>
              )}
            {/* Options */}
            <Box gridColumn={["1 / 3", null, null, null, "9 / 14"]}>
              <LaptopUpOnly>
                <BikePageHeader title={title} />
                <Body fontWeight="bold" color="night" mb="0.2rem">
                  {price}
                </Body>
                <LoadableAffirmWidget
                  pageType="product"
                  amount={Number(price?.replace("$", "")) || 0}
                />
                <ReviewsButton
                  bikeName={title}
                  w="fit-content"
                  mt="1.2rem"
                  isLoading={reviews.isLoading}
                  rating={reviews.avgRating}
                  reviewCount={reviews.numReviews}
                  reviews={R.uniqBy(R.prop("id"), reviews.seoReviews)}
                  productId={reviews.judgemeProduct?.product.id}
                  loadMoreReviews={
                    reviews.hasMoreToLoad ? reviews.loadMore : undefined
                  }
                  isLoadingMore={reviews.isLoadingMore}
                />
              </LaptopUpOnly>

              <Box mt="4rem">
                {COLORS.length > 0 && (
                  <LabeledSelector
                    label="Color"
                    selector={
                      <ColorPicker
                        colors={COLORS.map(({ hexCode, name }) => ({
                          colorCode: hexCode,
                          colorId: name,
                        }))}
                        onColorClick={(colorDetails) => {
                          if (colorDetails) {
                            const variant = variants.find(
                              (val) =>
                                val?.color?.hexCode === colorDetails.colorCode
                            )
                            if (variants) {
                              setCurrentVariantId(
                                decodeShopifyId(variant?.shopifyId ?? null),
                                "replaceIn"
                              )
                            }
                          }
                        }}
                        borderType="solid"
                        selectedColorId={curVariant?.color?.name ?? undefined}
                      />
                    }
                  />
                )}
                {COLORS.length === 0 && variants.length > 1 && (
                  <Select
                    onChange={(event) => {
                      setCurrentVariantId(
                        decodeShopifyId(event.currentTarget.value),
                        "replaceIn"
                      )
                    }}
                  >
                    {variants.map((variant) => (
                      <option
                        key={`accessory-variant-title-${variant?.internalTitle}`}
                        value={variant?.shopifyId ?? ""}
                      >
                        {variant?.title}
                      </option>
                    ))}
                  </Select>
                )}
              </Box>

              {curVariant && curVariant?.accessoryUpsell && (
                <UpsellAccessories
                  upsellProducts={curVariant?.accessoryUpsell}
                  onAccessoryChange={onAccessoryChange}
                  selectedAccessories={selectedAccessories}
                />
              )}

              {saleStatus === "available" && (
                <Button
                  w="100%"
                  mt="4.25rem"
                  mb={["4.375rem", null, null, null, 0]}
                  onClick={onAddToCart}
                  isLoading={isCartLoading}
                >
                  Add to Cart
                </Button>
              )}
              {saleStatus === "preorder" && (
                <Button
                  w="100%"
                  mt="4.25rem"
                  mb={["4.375rem", null, null, null, 0]}
                  onClick={onAddToCart}
                  isLoading={
                    !!(isCartLoading || (isPreorder && preorderInfoLoading))
                  }
                >
                  Pre-order
                </Button>
              )}
              {saleStatus === "out-of-stock" && (
                <Box mt="4.25rem" mb={["4.375rem", null, null, null, 0]}>
                  <Button w="100%" mb="1rem" theme="secondary" isDisabled>
                    Sold Out
                  </Button>
                  <BackInStockButton
                    variantId={currentVariantId ?? ""}
                    bikeTitle={title}
                    // bikeTitle=""
                    listName="BackInStock"
                    />
                </Box>
              )}
              {limitedQuantity && (
                <Body textAlign="center" color="dawn" mt="1.25rem">
                  Limited Quantity Remaining: Only {limitedQuantity} left
                </Body>
              )}
              {isPreorder && <Notice mt="1rem">{preorderLabel}</Notice>}
            </Box>
          </MaxWidthGrid>
          {data.contentfulAccessoryModel
            ?.childContentfulAccessoryModelLayoutJsonNode?.internal && (
            <LayoutBuilder
              data={JSON.parse(
                data.contentfulAccessoryModel
                  ?.childContentfulAccessoryModelLayoutJsonNode?.internal
                  .content ?? ""
              )}
              imageMap={imageMap}
            />
          )}
        </Box>
        <AddedToCartModal
          isOpen={addedToCartModal.isOpen}
          onClose={addedToCartModal.onClose}
          formattedTitle={title}
          image={
            (curVariant?.productListingImage?.fluid ??
              placeholderImage) as FluidObject
          }
          details={
            <>
              <DetailsItem>{curVariant?.color?.name}</DetailsItem>
            </>
          }
          price={price}
        />
      </CloseSideBar>
    </>
  )
}

export default AccessoryPage
